import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PageHeading from "@components/page-heading"
import HeadingBg from "@images/slider-img2.jpg"

export default function ThankYouQuote() {
  return (
    <Layout>
      <SEO />
      <PageHeading
        heading="Thank you for messaging us. We will contact you in the next 24 hours to get you our updated pricing."
        // subheading="We have recieved your sign-up details successfully. We'll reach out to you as soon as possible!"
        BgImg={HeadingBg}
        BtnLink="/"
        BtnLabel="BACK TO HOME"
        className="pseudo-none"
      />
    </Layout>
  )
}
